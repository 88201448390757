/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Test Screen Size
        var screenSize;

          function checkSize() {
            if($('.test-size').css('font-size') === '1px') {
              screenSize = 'xs';
            } else if($('.test-size').css('font-size') === '2px') {
              screenSize = 'sm';
            } else if($('.test-size').css('font-size') === '3px') {
              screenSize = 'md';
            } else if($('.test-size').css('font-size') === '4px') {
              screenSize = 'lg';
            }
          }

          checkSize();

          $(window).resize(function(){
            checkSize();
          });

          function isScrolledIntoView(elem)
          {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var elemTop = $(elem).offset().top;
            var elemBottom = elemTop + $(elem).height();
            return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop) && docViewTop !== 0);
          }

          var showSwipe = false;

          $(window).scroll(function() {
            if($('.swipicon-container').length) {
              if(isScrolledIntoView($('.swipicon-container')) && !$('.swipicon').hasClass('swipiconActive') && !showSwipe && (screenSize === 'xs' || screenSize === 'sm')) {
                showSwipe = true;
                $('.swipicon').fadeIn(1500,function(){
                  $('.swipicon').addClass('swipiconActive');
                }).delay(1600).fadeOut(1500);
              }
            }
          });



        // Scroll Up
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });

        // toggles hamburger and nav open and closed states
          var removeClass = true;
            $(".hamburger").click(function () {
            $(".hamburger").toggleClass('is-active');
            $("#sideNav").toggleClass('sideNav-open');
            $(".sideNavBody").toggleClass('sideNavBody-push');
            removeClass = false;
          });

          $(".sideNav").click(function() {
            removeClass = false;
          });

          document.addEventListener('touchstart', function() {
            if (removeClass) {
               $(".hamburger").removeClass('is-active');
               $("#sideNav").removeClass('sideNav-open');
               $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
          }, false);


          $('.partner-slide').slick({
             autoplay: true,
             autoplaySpeed: 5000,
             arrows: true,
             dots: true,
             slidesToShow: 4,
             variableWidth: true,
             responsive: [
              {
                breakpoint: 560,
                settings: {
                  slidesToShow: 1,
                  dots: false,
                  slidesToScroll: 1,
                  variableWidth: false,
                  arrows: false
                }
              }
            ]
          });

        // Google Map With Multiple Locations
        document.initMap = function() {
          var map;
          var infowindow = new google.maps.InfoWindow();
          function initialize_gmap(map_lat, map_long, zoom) {
            var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            var isDraggable = w > 480 ? true : false;
            var settings = {
              zoom: zoom,
              center: {lat: map_lat, lng: map_long},
              scrollwheel: false,
              draggable: isDraggable,
              mapTypeControl: true,
              mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
              navigationControl: true,
              navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
              mapTypeId: google.maps.MapTypeId.ROADMAP,
              disableDefaultUI: true,
              styles: [
                  {"featureType": "road","elementType": "geometry","stylers": [{"visibility": "off"}]},
                  {"featureType": "poi","elementType": "geometry","stylers": [{"visibility": "off"}]},
                  {"featureType": "landscape","elementType": "geometry","stylers": [{"color": "#fffffa"}]},
                  {"featureType": "water","stylers": [{"lightness": 50}]},
                  {"featureType": "road","elementType": "labels","stylers": [{"visibility": "off"}]},
                  {"featureType": "transit","stylers": [{"visibility": "off"}]},
                  {"featureType": "administrative","elementType": "geometry","stylers": [{"lightness": 40}]}
              ]
            };

            map = new google.maps.Map(document.getElementById("map_canvas_footer"), settings);
            var center;
            function calculateCenter() {
              center = map.getCenter();
            }
            google.maps.event.addDomListener(map, 'idle', function() {
              calculateCenter();
            });
            google.maps.event.addDomListener(window, 'resize', function() {
              map.setCenter(center);
            });
          }

          function addMarker(point_lat, point_long, address, title, directions) {
            var point = new google.maps.LatLng(point_lat, point_long);
            var contentString = '<div id="gmapContent" style="text-align:left;">'+
              '<a href="' + directions + '" target="_blank"><h5>' + title + '<br><small>(Click for directions)</small></h5></a>'+
              '<div id="bodyContent">'+
              '<p>' + address + '</p>'+
              '</div>'+
              '</div>';
            var companyMarker = new google.maps.Marker({
              position: point,
              map: map,
              title: title,
              icon: '/wp-content/uploads/images/map-marker-2.png',
            });
            google.maps.event.addListener(companyMarker, 'click', function() {
              infowindow.setContent(contentString);
              infowindow.open(map,this);
            });
          }

          var point_lat, point_long, address, title, directions, map_name;
          function getMapData(map_name) {
            point_lat = parseFloat($(map_name).find('.map-data').find('.point_lat').data('pointlat'));
            point_long = parseFloat($(map_name).find('.map-data').find('.point_long').data('pointlong'));
            address = $(map_name).find('.map-data').find('.address').data('address');
            title = $(map_name).find('.map-data').find('.title').data('title');
            directions = $(map_name).find('.map-data').find('.directions').data('directions');
            addMarker(point_lat, point_long, address, title, directions);
          }

          if($('#map_canvas_footer').length) {
            var map_lat = parseFloat($('#map_canvas_footer').siblings('.map-data').find('.map_lat').data('maplat'));
            var map_long = parseFloat($('#map_canvas_footer').siblings('.map-data').find('.map_long').data('maplong'));
            var zoom = parseFloat($('#map_canvas_footer').siblings('.map-data').find('.zoom').data('zoom'));
            initialize_gmap(map_lat, map_long, zoom);
          }
          if($('.location-map-millinocket').length) {
            map_name = '.location-map-millinocket';
            getMapData(map_name);
          }
          if($('.location-map-lincoln').length) {
            map_name = '.location-map-lincoln';
            getMapData(map_name);
          }
          if($('.location-map-bangor').length) {
            map_name = '.location-map-bangor';
            getMapData(map_name);
          }
          if($('.location-map-portland').length) {
            map_name = '.location-map-portland';
            getMapData(map_name);
          }
        };
        // var maps_api_key = 'AIzaSyCbWGO_wnZXRRP1HCDbCt5OSwPJs0nS0XE';
        var maps_api_key = 'AIzaSyCH0Udi49qfJJConxnz2rV_4HToqXhZNdo';
        var maps_callback = 'document.initMap';
        var maps_api_url = 'https://maps.google.com/maps/api/js';
        var maps_script_tag = document.createElement('script');
        maps_script_tag.setAttribute('type', 'text/javascript');
        maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
        maps_script_tag.setAttribute('defer', '');
        maps_script_tag.setAttribute('async', '');
        document.getElementsByTagName('body')[0].appendChild(maps_script_tag);


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
        function mapFooter() {
          // Google Map With Multiple Locations
          var map;
          var infowindow = new google.maps.InfoWindow();
          function initialize_gmap(map_lat, map_long, zoom) {
            var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            var isDraggable = w > 480 ? true : false;
            var settings = {
              zoom: zoom,
              center: {lat: map_lat, lng: map_long},
              scrollwheel: false,
              draggable: isDraggable,
              mapTypeControl: true,
              mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
              navigationControl: true,
              navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
              mapTypeId: google.maps.MapTypeId.ROADMAP,
              styles: [
                  {"featureType": "road","elementType": "geometry","stylers": [{"visibility": "off"}]},
                  {"featureType": "poi","elementType": "geometry","stylers": [{"visibility": "off"}]},
                  {"featureType": "landscape","elementType": "geometry","stylers": [{"color": "#fffffa"}]},
                  {"featureType": "water","stylers": [{"lightness": 50}]},
                  {"featureType": "road","elementType": "labels","stylers": [{"visibility": "off"}]},
                  {"featureType": "transit","stylers": [{"visibility": "off"}]},
                  {"featureType": "administrative","elementType": "geometry","stylers": [{"lightness": 40}]}
              ]
            };

            map = new google.maps.Map(document.getElementById("map_canvas"), settings);
            var center;
            function calculateCenter() {
              center = map.getCenter();
            }
            google.maps.event.addDomListener(map, 'idle', function() {
              calculateCenter();
            });
            google.maps.event.addDomListener(window, 'resize', function() {
              map.setCenter(center);
            });
          }

          function addMarker(point_lat, point_long, address, title, directions) {
            var point = new google.maps.LatLng(point_lat, point_long);
            var contentString = '<div id="gmapContent" style="text-align:left;">'+
              '<a href="' + directions + '" target="_blank"><h5>' + title + '<br><small>(Click for directions)</small></h5></a>'+
              '<div id="bodyContent">'+
              '<p>' + address + '</p>'+
              '</div>'+
              '</div>';
            var companyMarker = new google.maps.Marker({
              position: point,
              map: map,
              title: title,
              icon: '/wp-content/uploads/images/map-marker-2.png',
            });
            google.maps.event.addListener(companyMarker, 'click', function() {
              infowindow.setContent(contentString);
              infowindow.open(map,this);
            });
          }

          var point_lat, point_long, address, title, directions, map_name;
          function getMapData(map_name) {
            point_lat = parseFloat($(map_name).find('.map-data').find('.point_lat').data('pointlat'));
            point_long = parseFloat($(map_name).find('.map-data').find('.point_long').data('pointlong'));
            address = $(map_name).find('.map-data').find('.address').data('address');
            title = $(map_name).find('.map-data').find('.title').data('title');
            directions = $(map_name).find('.map-data').find('.directions').data('directions');
            addMarker(point_lat, point_long, address, title, directions);
          }

          if($('#map_canvas').length) {
            var map_lat = parseFloat($('#map_canvas').siblings('.map-data').find('.map_lat').data('maplat'));
            var map_long = parseFloat($('#map_canvas').siblings('.map-data').find('.map_long').data('maplong'));
            var zoom = parseFloat($('#map_canvas').siblings('.map-data').find('.zoom').data('zoom'));
            initialize_gmap(map_lat, map_long, zoom);
          }
          if($('.location-map-millinocket').length) {
            map_name = '.location-map-millinocket';
            getMapData(map_name);
          }
          if($('.location-map-lincoln').length) {
            map_name = '.location-map-lincoln';
            getMapData(map_name);
          }
          if($('.location-map-bangor').length) {
            map_name = '.location-map-bangor';
            getMapData(map_name);
          }
          if($('.location-map-portland').length) {
            map_name = '.location-map-portland';
            getMapData(map_name);
          }
        }
        $( window ).load(function() {
          mapFooter();
        });
      },
      finalize: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
